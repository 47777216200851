import {FC, useState, createContext, useContext} from 'react'
import {
  initialFileModalView,
 FileModalContextProps,
 WithChildren,
} from '../../../../_adminPage/helpers'
import { IFileDetails } from '../../../../_adminPage/helpers_custom'

import {FileNewModal} from '../file-new-modal/FileNewModal'


const FileEditModalPage = () => {
  const {fileNameForUpdate} = useFileModalView()
  const {fileNameForAdd} = useFileModalView()
  

  return (
    <>
      {fileNameForAdd !== undefined && <FileNewModal />}
    </>
  )
}


const FileModalContext = createContext<FileModalContextProps>(initialFileModalView)

const FileModalProvider: FC<WithChildren> = ({children}) => {
  const [fileNameForUpdate, setFileNameForUpdate] = useState<IFileDetails | undefined>()
  const [fileNameForAdd, setFileNameForAdd] = useState<string | undefined>()
  const [isImage, setIsImage] = useState<boolean>(initialFileModalView.isImage || false)


  return (
    <FileModalContext.Provider
      value={{
       fileNameForUpdate,
        setFileNameForUpdate,
        fileNameForAdd,
        setFileNameForAdd,
        isImage,
        setIsImage,
      }}
    >
      {children}
      <FileEditModalPage/>
    </FileModalContext.Provider>
  )
}

const useFileModalView = () => useContext(FileModalContext)

export {FileModalProvider,useFileModalView}
