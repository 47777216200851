/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState} from 'react'
import {useQuery} from 'react-query'
import {
  createObjectResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../_adminPage/helpers'
import {getCountries} from './requests'
import {CountryQueryResponse} from './model/_model'

const QueryCountryResponseContext = createObjectResponseContext<CountryQueryResponse>(initialQueryResponse)
const QueryCountryResponseProvider: FC<WithChildren> = ({children}) => {
 
  const [query, setQuery] = useState<string>('/country')

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.COUNTRY_LIST}-${query}`,
   () => {
      return getCountries()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryCountryResponseContext.Provider value={{isLoading: isFetching, refetch, response,query}}>
      {children}
    </QueryCountryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryCountryResponseContext)

const useQueryResponseCountryData = () => {
  const {response} =  useQueryResponse()
  if (!response) {
    return []
  }
  return response?.data?.countries || []
}


const useQueryResponseLoadingCountry = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryCountryResponseProvider,
  useQueryResponse,
  useQueryResponseCountryData,
  useQueryResponseLoadingCountry,
}
