/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState} from 'react'
import {useQuery} from 'react-query'
import {
  createCustomResponseContext,
  initialQueryResponse,
  WithChildren,
} from '../../../_adminPage/helpers'
import {ISupplier} from './_models'
import { getSuppliers } from './_requests';

const QueryResponseSuppliersContext = createCustomResponseContext<ISupplier>(initialQueryResponse)
const QueryResponseSuppliersProvider: FC<WithChildren> = ({children}) => {
  const [query, setQuery] = useState<string>("/suppliers")


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${query}`,
    () => {
      return getSuppliers()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseSuppliersContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseSuppliersContext.Provider>
  )
}

const useQueryResponseSuppliers = () => useContext(QueryResponseSuppliersContext)

const useQueryResponseSuppliersData = () => {
  const {response} = useQueryResponseSuppliers()
  if (!response) {
    return []
  }

  return response || []
}



const useQueryResponseSuppliersLoading = (): boolean => {
  const {isLoading} = useQueryResponseSuppliers()
  return isLoading
}

export {
  QueryResponseSuppliersProvider,
  useQueryResponseSuppliers,
  useQueryResponseSuppliersData,
  useQueryResponseSuppliersLoading,
}
