import {ImgNewModalForm} from './ImgNewModalForm'
import {useImgModalView} from '../core/ImgModalProvider'

const ImgNewModalFormWrapper = () => {
  const {imgNameForUpdate,selectedProduct, setImgNameForUpdate,setSelectedProduct} = useImgModalView()
  // const enabledQuery: boolean = isNotEmpty(imgNameForUpdate)


  // const {
  //   isLoading,
  //   data: user,
  //   error,
  // } = useQuery(
  //   `/product/${imgNameForUpdate}`,
  //   () => {
  //     // return getProductById(imgNameForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setImgNameForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  // if (!fileNameForUpdate) {
  //   return <ImgNewModalForm isUserLoading={isLoading} file={{name: undefined}} />
  // }

  // if (!isLoading && !error && user) {
  //   return <ImgNewModalForm isUserLoading={isLoading} file={user} />
  // }

  if (!setSelectedProduct) {
    return <ImgNewModalForm isUserLoading={false} file={{name: undefined}} />
  }

 else {
    return <ImgNewModalForm isUserLoading={false} file={{name: undefined}}/>
  }


}

export {ImgNewModalFormWrapper}
