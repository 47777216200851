/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createObjectResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../_adminPage/helpers'
import {getLanguages} from './requests'
import {LanguageQueryResponse} from './model/_model'

const QueryLanguageResponseContext = createObjectResponseContext<LanguageQueryResponse>(initialQueryResponse)
const QueryLanguageResponseProvider: FC<WithChildren> = ({children}) => {
 
  const [query, setQuery] = useState<string>('/language')

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.LANGUAGE_LIST}-${query}`,
    () => {
      return getLanguages()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )



  return (
    <QueryLanguageResponseContext.Provider value={{isLoading: isFetching, refetch, response,query}}>
      {children}
    </QueryLanguageResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryLanguageResponseContext)

const useQueryResponseLanguageData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data?.languages || []
}


const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryLanguageResponseProvider,
  useQueryResponse,
  useQueryResponseLanguageData,
  useQueryResponseLoading,
}
