import {FileNewModalForm} from './FileNewModalForm'
import {isNotEmpty} from '../../../../_adminPage/helpers'
import {useFileModalView} from '../core/FileModalProvider'

const FileNewModalFormWrapper = () => {
  const {fileNameForUpdate} = useFileModalView()
  const enabledQuery: boolean = isNotEmpty(fileNameForUpdate)
  // const {
  //   isLoading,
  //   data: user,
  //   error,
  // } = useQuery(
  //   `${QUERIES.USERS_LIST}-user-${fileNameForUpdate}`,
  //   () => {
  //     return getUserById(fileNameForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setFileNameForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  // if (!fileNameForUpdate) {
  //   return <FileNewModalForm isUserLoading={isLoading} file={{name: undefined}} />
  // }

  // if (!isLoading && !error && user) {
  //   return <FileNewModalForm isUserLoading={isLoading} file={user} />
  // }

  if (!fileNameForUpdate) {
    return <FileNewModalForm isUserLoading={false} file={{name: undefined}} />
  }

 else {
    return <FileNewModalForm isUserLoading={false} file={{name: undefined}}/>
  }

  return null
}

export {FileNewModalFormWrapper}
