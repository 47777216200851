import {FC, useState, createContext, useContext} from 'react'
import {
 ImgModalContextProps,
 initialImgModalView,
 WithChildren,
} from '../../../../_adminPage/helpers'
import { IFileDetails } from '../../../../_adminPage/helpers_custom'
import { IProductDetails } from '../../../modules/products/components/core/_models'
import {ImgNewModal} from '../img-new-modal/ImgNewModal'

const ImgEditModalPage = () => {
  const {selectedProduct} = useImgModalView()
  

  return (
    <>
      {selectedProduct !== undefined && <ImgNewModal />}
    </>
  )
}


const ImgModalContext = createContext<ImgModalContextProps>(initialImgModalView)

const ImgModalProvider: FC<WithChildren> = ({children}) => {
  const [imgNameForUpdate, setImgNameForUpdate] = useState<IFileDetails | undefined>()
  const [selectedProduct, setSelectedProduct] = useState<IProductDetails>()


  return (
    <ImgModalContext.Provider
      value={{
       imgNameForUpdate,
        setImgNameForUpdate,
        selectedProduct,
        setSelectedProduct,
      }}
    >
      {children}
      <ImgEditModalPage/>
    </ImgModalContext.Provider>
  )
}

const useImgModalView = () => useContext(ImgModalContext)

export {ImgModalProvider,useImgModalView}
