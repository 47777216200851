import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_adminPage/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_adminPage/assets/ts/_utils'
import { useAuth } from '../modules/auth'
import { WithChildren } from '../../_adminPage/helpers'
const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage2 = lazy(() => import('../modules/apps/user-management/UsersPage'))


  const UserPageProvider = lazy(() => import('../modules/users/submodules/edit-user/userPageProvider'))
  const UsersPage = lazy(() => import('../modules/users/routes/UsersPage'))

  const AdminPage = lazy(() => import('../modules/admins/submodules/edit-admin/adminPageProvider'))
  const AdminsPage = lazy(() => import('../modules/admins/routing/Admins'))

  const SupplierPage = lazy(() => import('../modules/suppliers/submodules/edit-supplier/routes/SupplierPage'))
  const SuppliersPage = lazy(() => import('../modules/suppliers/routes/SuppliersRoute'))

  const OrderPage = lazy(() => import('../modules/orders/submodules/edit-order/routing/OrderRoutes'))
  const OrdersPage = lazy(() => import('../modules/orders/routing/OrdersPage'))

  const ProductPage = lazy(() => import('../modules/products/submodules/edit-product/routing/ProductPage'))
  const ProductsPage = lazy(() => import('../modules/products/routing/ProductsRoutes'))

  const NewUserPage = lazy(() => import('../modules/new/RoutesToNewPages'))


  const PricesPage = lazy(() => import('../modules/prices/routing/PricesRoutes'))
  const PricePage = lazy(() => import('../modules/prices/submodules/edit-price/routing/PriceRoutes'))


  const BrandsPage = lazy(() => import('../modules/brands/routing/BrandsRoutes'))
  const BrandPage = lazy(() => import('../modules/brands/submodules/edit-brand/routing/BrandRoutes'))


  const CategoriesPage = lazy(() => import('../modules/product-categories/routing/CategoriesRoutes'))
  const CategoryPage = lazy(() => import('../modules/product-categories/submodules/edit-product-category/routing/ProductCategoryPage'))


  const TypesPage = lazy(() => import('../modules/product-types/routing/TypesPage'))
  const TypePage = lazy(() => import('../modules/product-types/submodules/edit-type/routing/ProductTypePage'))

  const UnitsPage = lazy(() => import('../modules/product-units/routing/UnitsRoutes'))
  const UnitPage = lazy(() => import('../modules/product-units/submodules/edit-unit/routing/ProductUnitPage'))

  const DocumentsPage = lazy(() => import('../modules/documents/routing/Documents'))


  const { currentUser } = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={'/user/account/overview/' + currentUser?.data.user?.id} />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}

        {/* Lazy Modules */}

        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='admins/*'
          element={
            <SuspensedView>
              <AdminsPage />
            </SuspensedView>
          }
        />
        <Route
          path='new/*'
          element={
            <SuspensedView>
              <NewUserPage />
            </SuspensedView>
          }
        />

        <Route
          path='user/account/*'
          element={
            <SuspensedView>
              <UserPageProvider />
            </SuspensedView>
          }
        />

        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />

        <Route
          path='product/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />


        <Route
          path='suppliers/*'
          element={
            <SuspensedView>
              <SuppliersPage />
            </SuspensedView>
          }
        />

        <Route
          path='supplier/account/*'
          element={
            <SuspensedView>
              <SupplierPage />
            </SuspensedView>
          }
        />

        <Route
          path='order/*'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />

        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />

        <Route
          path='price/*'
          element={
            <SuspensedView>
              <PricePage />
            </SuspensedView>
          }
        />

        <Route
          path='prices/*'
          element={
            <SuspensedView>
              <PricesPage />
            </SuspensedView>
          }
        />

        <Route
          path='documents/*'
          element={
            <SuspensedView>
              <DocumentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='brands/*'
          element={
            <SuspensedView>
              <BrandsPage />
            </SuspensedView>
          }
        />
        <Route
          path='brand/*'
          element={
            <SuspensedView>
              <BrandPage />
            </SuspensedView>
          }
        />

        <Route
          path='types/*'
          element={
            <SuspensedView>
              <TypesPage />
            </SuspensedView>
          }
        />

        <Route
          path='type/*'
          element={
            <SuspensedView>
              <TypePage />
            </SuspensedView>
          }
        />

        <Route
          path='units/*'
          element={
            <SuspensedView>
              <UnitsPage />
            </SuspensedView>
          }
        />

        <Route
          path='unit/*'
          element={
            <SuspensedView>
              <UnitPage />
            </SuspensedView>
          }
        />

        <Route
          path='categories/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='category/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />

        <Route
          path='admin/account/*'
          element={
            <SuspensedView>
              <AdminPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
