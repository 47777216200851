import { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialUser, File, ImgClass } from '../core/_models'
import { useImgModalView } from '../core/ImgModalProvider'
import { FileListLoading } from '../components/loading/FileListLoading'
import { fileRegister, updateProduct } from '../core/_requests'
import { useQueryClient } from 'react-query'
import { IFileDetails } from '../../../../_adminPage/helpers_custom'

type Props = {
  isUserLoading: boolean
  file: File
}

const newFileSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const ImgNewModalForm: FC<Props> = ({ file, isUserLoading }) => {
  const { setSelectedProduct, selectedProduct } = useImgModalView()

  const fileInputRef = useRef<any>(null);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [imgFiles, setImgFiles] = useState<File[]>([]);

  const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const progressRefFiles = useRef<HTMLDivElement>(null);
  const uploadRefFiles = useRef<HTMLSpanElement>(null);;
  const progressRefFilesCounter = useRef<HTMLDivElement>(null);

  const [userForEdit] = useState<File>({
    ...file,
    name: file.name || initialUser.name,
  })

  const queryClient = useQueryClient();
  useEffect(() => {
    setLoading(true)
    let filteredArr = selectedFiles.reduce((acc: any, current: File) => {
      const x = acc.find((item: File) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    setValidFiles([...filteredArr]);
    setLoading(false)

    //  setImages([...filteredArr]);

  }, [selectedFiles]);

  const cancel = () => {
    setSelectedProduct(undefined)
  }


  const preventDefault = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    // e.stopPropagation();
  }

  const dragOver = (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
  }

  const dragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
  }

  const dragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    preventDefault(e);
  }


  const handleFiles = (files: any[]) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles(prevArray => [...prevArray, files[i]] as any);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles(prevArray => [...prevArray, files[i]] as any);
        setErrorMessage('File type not permitted');
        setUnsupportedFiles(prevArray => [...prevArray, files[i]] as any);
      }
    }
  }
  const fileDrop = (e: any) => {
    preventDefault(e);
    const files = e.dataTransfer.files;

    if (files.length) {
      handleFiles(files);
    }
  }

  const filesSelected = () => {
    if (fileInputRef?.current?.files?.length) {
      handleFiles(fileInputRef?.current?.files);
    }
  }
  const validateFile = (file: any) => {

    const validTypes = ['image/jpg', 'image/png', 'image/gif', 'image/webp'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  }


  const setFilesPath = async () => {
    const newFiles = [];

    const options = {
      onUploadProgress: (progressEvent: any) => {
        // const uploadPercentage = (selectedFiles.length - newFiles.length) > 0 ? Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / (selectedFiles.length - newFiles.length)) : Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        const uploadPercentage = 100;

        if (progressRefFiles.current !== null) {
          progressRefFiles.current.innerHTML = `${uploadPercentage}%`;
          progressRefFiles.current.style.width = `${uploadPercentage}%`;
        }

        if (uploadPercentage === 100 && uploadRefFiles.current !== null) {
          uploadRefFiles.current.innerHTML = 'File(s) Uploaded';

        }
      }
    };
    for (let i = 0; i < validFiles.length; i++) {
      const formData = new FormData();

      try {
        formData.append('file', validFiles[i] as any);
        console.log("valid files: ", validFiles[i])
        const { data: resp } = await fileRegister(formData, options);
        var FilesData: any = Object.values(resp)[1] as any;
        var FilesDataArray: IFileDetails = Object.values(FilesData)[0] as IFileDetails;
        newFiles.push(FilesDataArray)


      } catch (error) {

        if (progressRefFiles.current !== null && uploadRefFiles.current !== null) {
          uploadRefFiles.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
          progressRefFiles.current.style.backgroundColor = 'red';
        }
      }
    }

    return newFiles;
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {


    console.log("e: ", e.target.value)
    console.log("selected: ", validFiles)

    // const newField = selectedFiles[Number(e.target.getAttribute("data-index"))];
    // newField.name = e.target.value;
    // const newArray = [...selectedFiles.slice(0, Number(e.target.getAttribute("data-index"))), newField, ...selectedFiles.slice(Number(e.target.getAttribute("data-index")) + 1)];
    // newArray[Number(e.target.getAttribute("data-index"))].name = e.target.value;

    var example = new ImgClass();

    console.log("ex: ", example)
    let data2 = [] = [...validFiles];
    console.log("data: ", data2)

    const key: keyof File = 'name';
    data2[i][key] = e.target.value

    console.log("data2: ", data2)
    // const item = data2[i] as File;
    // const newField = {name:e.target.value,path:item.path,fileB64:item.fileB64}
    // item.name=e.target.value
    // console.log("item:",item)
    // const newArray = [...validFiles.slice(0, i),newField,...validFiles.slice(i + 1)];


    setValidFiles(data2);
  }

  const removeFile = (name: string) => {
    const index = validFiles.findIndex(e => e.name === name);
    const index2 = selectedFiles.findIndex(e => e.name === name);
    const index3 = unsupportedFiles.findIndex(e => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    // setImages([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: newFileSchema,
    onSubmit: async (values, { setSubmitting }) => {


      setSubmitting(true)
      try {
        const filesPath = await setFilesPath()


        // for (let index = 0; index < filesPath.length; index++) {
        //   selectedProduct?.images.push(filesPath[index])
        // }


        const product = await updateProduct(selectedProduct?.id as string, selectedProduct?.name as string, Number(selectedProduct?.price), selectedProduct?.category_id as string, selectedProduct?.product_type_id as string, selectedProduct?.manufacturer_id as string, selectedProduct?.highlight as number, selectedProduct?.unit_id, selectedProduct?.min_units as number, filesPath, selectedProduct?.documents as IFileDetails[], selectedProduct?.active as number, selectedProduct?.description as string, selectedProduct?.warranty as string)
        queryClient.invalidateQueries([`/product/${selectedProduct?.id}`])
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}



            {/* begin::Image input */}
            <section className="container">
              <div className="drop-container"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              // onClick={fileInputClicked}
              >
                <div className="drop-message">
                  <div className="upload-icon"></div>
                  Drag & Drop images here or click to select file(s)
                </div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple
                  onChange={filesSelected}
                />
              </div>
              <aside>

                {
                  validFiles.length > 0 &&
                  <>
                    <h4>Files</h4>
                    {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ''}

                    <ul className={"file-manage-item p-0"}>
                      {validFiles.map((file: File, index) => (
                        <li key={file.name} className="d-flex justify-content-between position-relative">
                          <div>
                            <input style={{ marginBottom: '10px' }} name='name' value={file.name} onChange={event => handleChangeName(event, index)}></input>  - {file.size as string} bytes
                          </div>
                          <span style={{ top: '0px' }} className="file-remove" onClick={() => removeFile(file.name as string)}>X</span>

                        </li>
                      ))
                      }</ul>
                  </>
                }
              </aside>
            </section>
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>


        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Add</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <FileListLoading />}
    </>
  )
}

export { ImgNewModalForm }
