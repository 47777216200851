import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_adminPage/layout/core'
import {DataModel} from './_models'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_adminPage/helpers';


type AuthContextProps = {
  auth: DataModel | undefined
  saveAuth: (auth: DataModel | undefined) => void
  currentUser: DataModel | undefined
  setCurrentUser: Dispatch<SetStateAction<DataModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<DataModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<DataModel | undefined>()
  const saveAuth = (auth: DataModel | undefined) => {
   
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {auth,currentUser, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
 
    const requestUser = async (user: DataModel) => {
      try {
    
        if (!didRequest.current) {
          // const {data} = await getUserByToken(apiToken)
          if (user) {
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

console.log("auth: ",auth)
    if (auth && auth?.data?.token) {
       requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
