import axios, { AxiosResponse } from 'axios'
import { UserIdQueryResponse } from './_models'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
const ADMIN_URL = `${APPZ4_BACKEND_URL}/user`


const getUserById = (id: string): Promise<any> => {
 
  return axios
    .get(`${ADMIN_URL}/${id}`)
    .then((response: AxiosResponse<UserIdQueryResponse>) => response.data.data?.user)
}
export function updateUser(
  id:string,
  name: string,
  active:number,
  country_id: string,
  currency_id:string,
  language_id:string,
  permission:number,
  // price_category_id: string,
  retail:string,
  vat_number:string,
  phone:string
) {
  return axios.patch(ADMIN_URL+"/"+id, {
    name:name,
    active:active,
    country_id:country_id,
    currency_id:currency_id,
    language_id:language_id,
    permission:permission,
    // price_category_id:price_category_id,
    retail:retail,
    vat_number:vat_number,
    phone:phone
  })
}



export { getUserById }

