import {KTSVG} from '../../../../_adminPage/helpers'
import {useImgModalView} from '../core/ImgModalProvider'

const ImgNewModalHeader = () => {
  const {setSelectedProduct} = useImgModalView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Add File</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setSelectedProduct(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ImgNewModalHeader}
