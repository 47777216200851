import { IPrice, IPriceDetails } from "../../../app/modules/prices/components/core/_models";
import { Category } from "../../../app/modules/product-categories/components/core/_models";
import { IProductDetails } from "../../../app/modules/products/components/core/_models";
import { fileRegister } from "../../../app/modules/products/components/core/_requests";
import { ISupplier } from "../../../app/modules/suppliers/components/core/_models";
import { IFileDetails } from "./models";

//Convert response after fetch to image component


function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','),
    mime = (arr[0] as any).match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function removeSpace(item: string): string {

  if (item) {
    return item.replace(/ /g, '');
  }

  return "";
}

function isHasValue(value: string): boolean {
  if (value) {
    return true;
  }
  return false;
}

function isActive(value: string): number {
  if (value == "active") {
    return 1;
  }
  return 0;
}

//search products which are not members on supplier list
function differenceProducts(a1: IProductDetails, a2: ISupplier) {
  var result = [] as any[];
  a1.map((item: any) => {
    if (a2.inventory.map(elem => elem.product_id).indexOf(item.id) === -1) {
      result.push(item);
    }
  })
  return result;
}

//calculate stars for Supplier Rate
function calculateStarsForSupplier(positiveRate: number, negativeRate: number): number {
  const maxValue = positiveRate + negativeRate;
  //one unit star value
  const starValue = maxValue / 5;
  //positive star value dec
  const result = maxValue - (negativeRate * starValue);
  //stars number
  return Math.round(result / starValue);
}

function isManagedSupplier(id: string | number) {
  if (id === "3" || id == 3) {
    return true;
  }
  return false;
}

//Convert response after fetch to images component
const convertImageToAntdInput = (item: any) => {
  return [{ uid: item.id, name: item.substring(item?.lastIndexOf("/") + 1, item?.lastIndexOf(".")), url: process.env.REACT_APP_APPZ4_BACKEND_ROOT + item }]
}

const convertImagesToAntdInput = (images: any[]) => {
  return images.map(
    (item: IFileDetails) => {
      return { uid: item.id, name: item.path?.substring(item?.path?.lastIndexOf("/") + 1, item?.path?.lastIndexOf(".")) as string, url: process.env.REACT_APP_APPZ4_BACKEND_ROOT + item?.path }
    })
}


const setImagesPathWithoutLoader = async (fileList: any[]) => {
  const newFiles = [];

  for (let i = 0; i < fileList.length; i++) {

    const formData = new FormData();
    try {
      formData.append('file', fileList[i].file);
      const { data: resp } = await fileRegister(formData);
      var imgData: any = Object.values(resp)[1] as any;

      // response.data.img attr
      var imgDataArray: IFileDetails = Object.values(imgData)[0] as IFileDetails;
      newFiles.push(imgDataArray)
    } catch (error) {


    }
  }


  return newFiles;
}

const setImagePathWithoutLoader = async (fileList: any[]) => {

  const formData = new FormData();
  let imgDataObj: IFileDetails | undefined = undefined;

  try {
    formData.append('file', fileList[0].file);
    const { data: resp } = await fileRegister(formData);
    var imgData: any = Object.values(resp)[1] as any;
    imgDataObj = Object.values(imgData)[0] as IFileDetails;
  }
  catch (error) {

  }

  return imgDataObj;
}


// A comparer used to determine if two entries are equal.
export const isSameImg = (a: any, b: any) => a.uid === b.uid && a.url === b.url && a.name === b.name;


export const isSameCategory = (a: any, b: any) => a.id === b.category_id;

// Get items that only occur in the left array,
// using the compareFunction to determine equality.
export const onlyInLeft = (left: any, right: any, compareFunction: any) =>
  left.filter((leftValue: any) =>
    !right.some((rightValue: any) =>
      compareFunction(leftValue, rightValue)));

// Get items that only occur in the right array,
// using the compareFunction to determine equality.
export const onlyInRight = (left: any, right: any, compareFunction: any) =>
  right.filter((rightValue: any) =>
    !left.some((leftValue: any) =>
      compareFunction(leftValue, rightValue)));

export const setDeletedImages = (initFileList: any, fileList: any) => {
  const deletedImgsResult = onlyInLeft(initFileList, fileList, isSameImg);
  let result: string[] = [];
  deletedImgsResult.map((item: any) => result.push(item.uid))
  return result;
}

export const initPriceValues  = (prices: any[]) => {

  const result = prices.map((item: any, index: any) => {
    return {
      category_id: item.category_id,
      retail_percentage:  item.retail_percentage > 1 ? Number((item?.retail_percentage - 1 ).toFixed(2)): 0,
      wholesale_percentage: item.wholesale_percentage > 1 ? Number((item.wholesale_percentage - 1 ).toFixed(2)): 0,
      name:item.name
    }
  })

  return result;
}


export const addItemToArray  = (categories: any[],data: IPrice) => {

  const diff = onlyInLeft(categories, data?.categories_percentages, isSameCategory)

  if (diff?.length > 0) {

    diff.map((item: Category) => {
      data.categories_percentages.push({
        retail_percentage: 0,
        wholesale_percentage: 0,
        name: item?.name as string,
        category_id: item?.id as string,
        category_details: {
          id: item.id as string,
          name: item.name as string,
          img: item.img,
        }
      })
    })
  }

  return data;
}

const isActiveButtons = (button1: number,button2: number): boolean => {

  if (button1 == button2 || button1 == button2+1 || button1 == button2+2) {
    return true;
  } else {
    return false;
  }
}



export {
  setImagePathWithoutLoader,
  isActiveButtons,
  setImagesPathWithoutLoader,
  convertImagesToAntdInput,
  convertImageToAntdInput,
  getBase64,
  dataURLtoFile,
  removeSpace,
  isHasValue,
  isActive,
  differenceProducts,
  calculateStarsForSupplier,
  isManagedSupplier
}