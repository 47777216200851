import axios from 'axios'
import {AuthModel, UserModel,DataModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL_CLIENT

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const LOGIN_URL_APPZ4 = `${APPZ4_BACKEND_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const FORGOT_PASSWORD_URL = `${APPZ4_BACKEND_URL}/user/forgotten-password`
export const REQUEST_PASSWORD_URL = `${APPZ4_BACKEND_URL}/user/reset-password`

// Server should return AuthModel
// export function login(email: string, password: string) {
//   return axios.post<AuthModel>(LOGIN_URL_APPZ4 , {
//     email,
//     password,
//   })
// 

export function login(email: string, password: string) {
  return axios.post<DataModel>(LOGIN_URL_APPZ4 , {
    email,
    password,
  })
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should send e-mail with newPassword url
export function forgottPassword(email: string,url: string) {
  return axios.post<{result: boolean}>(FORGOT_PASSWORD_URL, {
    email,
    url
  })
}


export function requestPassword(token: string,password: string,password_confirmation: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    token,
    password,
    password_confirmation
  })
}

export function getUserByToken(token: string) {
  return axios.post<DataModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
