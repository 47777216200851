import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_adminPage/helpers'
import {Category, CategoriesQueryResponse, CategoryIdQueryResponse, IFileDetails} from './_models'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL

const CATEGORY_URL = `${APPZ4_BACKEND_URL}/category`

export const FILE_URL = `${APPZ4_BACKEND_URL}/file`



const getCategories = (query: string): Promise<any> => {
  return axios
    .get(`${CATEGORY_URL}`)
    .then((d: AxiosResponse<CategoriesQueryResponse>) => d.data.data?.categories)
}

const getCategoryById = (id: string): Promise<any> => {
  return axios
    .get(`${CATEGORY_URL}/${id}`)
    .then((response: AxiosResponse<CategoryIdQueryResponse>) => response.data.data?.category)
}

export function updateCategory(
  id:string,
  name: string,
  img?: IFileDetails | undefined
) {
  return axios.patch(CATEGORY_URL+"/"+id, {
    name,
    img:img?.path
  })
}


// const getImages = (base64:string): Promise<any> => {
//   return axios
//     .get(`${GET_IMAGES_URL}${base64}`)
//     .then((d: AxiosResponse<any>) => d.data)
// }

export function fileRegister(
  formData:FormData,
 ) {
   return axios.post(FILE_URL, 
     formData, {
     headers: {
       "Content-Type": "multipart/form-data",
     },
   })
   
 }
 

export function categoryRegister(
  name:string,
  img:string
) {
  return axios.post(CATEGORY_URL ,  {name:name,img:img},)
}



const deleteCategory = (userId: ID): Promise<void> => {
  return axios.delete(`${CATEGORY_URL}/${userId}`).then(() => {})
}



export {getCategories, deleteCategory,getCategoryById}
