import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { User } from '../../core/_models'
import clsx from 'clsx'
import { UserListLoading } from '../../components/loading/UserListLoading'
import { errorHandle } from '../../../../../../helpers/error-handler/errorHandler'
import { updateUser } from '../../core/_requests'
import { useListView } from '../../../../../../layout/components/header/userProvider/ListViewProvider'
import { useQueryResponseUser } from '../../../../../../layout/components/header/userProvider/QueryResponseProviderUser'
import { useQueryResponseCountryData } from '../../../../../../../app/globalProviders/Country/core/QueryResponseProviderCountry'
import { useQueryResponseLanguageData } from '../../../../../../../app/globalProviders/Language/core/QueryResponseProviderLang'

type Props = {
  isUserLoading: boolean
  User: User
}

const editUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  phone: Yup.string().required('Kötelező mező').nullable(),
})

const UserEditModalForm: FC<Props> = ({ User, isUserLoading }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [UserForEdit] = useState<User>({
    ...User,
  })



  // const queryClient = useQueryClient()

  const { setItemForUpdate } = useListView()
  const { refetch } = useQueryResponseUser()

  const countries = useQueryResponseCountryData()
  const languages = useQueryResponseLanguageData()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      //New request to suppliers
      refetch()
    }
    setItemForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: UserForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        const updatedData = Object.assign(UserForEdit, values)

        await updateUser(updatedData.id as string, updatedData.name as string, updatedData.active as number, updatedData.country_id as string, "1", updatedData.language_id as string, 2, updatedData.retail as string, updatedData.vat_number as string, updatedData.phone as string)

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false)
          cancel(true)
          // queryClient.invalidateQueries([`user-${updatedData.id}`])
          resetForm()
        }, 2500)

      } catch (error) {
        setStatus('The details is incorrect')
        const errorText = errorHandle(error)
        setStatus(errorText)
        setTimeout(() => {
          setStatus("")
        }, 2500)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_User_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
        >

          <div className='fv-row mb-8'>
            <label className='fw-bold fs-4 my-2'>Contact:</label>
          </div>

          <div className='fv-row mb-8'>
            <div className='row'>

              <div className='col-lg-6'>
                <label className='fw-bold fs-6 mb-2 required'>Name</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='Name'
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.lName && formik.errors.lName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.lName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='fv-row mb-8'>
            <div className='row'>
              <div className='col-lg-6'>
                <label className='fw-bold fs-6 mb-2 required'>E-mail</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='E-mail'
                  {...formik.getFieldProps('email')}
                  type='text'
                  name='email'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6'>
                <label className='fw-bold fs-6 mb-2 required'>Telefonszám</label>
                <input
                  placeholder='Telefon'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('phone')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.phone && formik.errors.phone,
                    },
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.phone}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='fv-row mb-8'>
            <div className='row'>
              <div className='col-lg-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Country</span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('country_id')}
                  >
                    <>
                      <option value={(UserForEdit as User)?.country_id}>{countries.find((country) => country.id === (UserForEdit as User)?.country_id)?.name}</option>

                      {countries && countries.map((item) => {
                        return (item.id !== (UserForEdit as User).country_id && <option key={item.code + '-' + item.id} value={item.id as any}>{item.name}</option>)
                      })}
                    </>

                  </select>
                  {formik.touched.country_id && formik.errors.country_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.country_id}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Language</label>
                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('language_id')}
                  >
                    <>
                      {languages && languages.map((item) => {
                        return (<option key={item.code + '-' + item.id} value={item.code}>{item.name}</option>)
                      })}
                    </>

                  </select>
                  {formik.touched.language_id && formik.errors.language_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.language_id}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* end::Scroll */}

      {formik.status && (
        <div className='alert alert-danger mt-6'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-10 bg-light-success p-4 rounded mt-6'>
          <div className='text-success'>Changes were successfully saved!</div>
        </div>
      )}

      {/* begin::Actions */}
      <div className='text-center pt-15'>
        <button
          type='reset'
          onClick={() => cancel()}
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
          disabled={formik.isSubmitting || isUserLoading || success}
        >
          Cancel
        </button>

        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={isUserLoading || formik.isSubmitting || !formik.touched || success}
        >
          <span className='indicator-label'>Save</span>
          {(formik.isSubmitting || isUserLoading) && (
            <span className='indicator-progress'>
              Please waiting...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Actions */}
    </form>
      { (formik.isSubmitting || isUserLoading) && <UserListLoading /> }
    </>
  )
}

export { UserEditModalForm }
