import { Dispatch, SetStateAction } from "react"
import { Admin } from "../../../app/modules/admins/components/core/_models"
import { Brand } from "../../../app/modules/brands/components/core/_models"
import { IPrice } from "../../../app/modules/prices/components/core/_models"
import { Category } from "../../../app/modules/product-categories/components/core/_models"
import { Type } from "../../../app/modules/product-types/components/core/_models"
import { Unit } from "../../../app/modules/product-units/components/core/_models"
import { IProductDetails } from "../../../app/modules/products/components/core/_models"
import { ISupplier } from "../../../app/modules/suppliers/components/core/_models"
import { User } from "../../../app/modules/users/components/core/_models"
import { ID } from "../../helpers/crud-helper/models"
import { Order } from "../../../app/modules/orders/core/_models"

export type ResponseImgModel ={
  extension: string
  id: ID
  media_url: string
}

export type StatusState = {
  order_status?: unknown
}

export type ProductListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: IProductDetails | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: IProductDetails
  setItemForUpdate: Dispatch<SetStateAction<IProductDetails | undefined>>
  isAllSelected: boolean
  disabled: boolean
}


export const initialProductListView: ProductListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type SupplierListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: ISupplier | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: ISupplier
  setItemForUpdate: Dispatch<SetStateAction<ISupplier | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialSupplierListView: SupplierListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type UserListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: User
  setItemForUpdate: Dispatch<SetStateAction<User | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialUserListView: UserListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}


export type AdminListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Admin
  setItemForUpdate: Dispatch<SetStateAction<Admin | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialAdminListView: AdminListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type PricesListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: IPrice
  setItemForUpdate: Dispatch<SetStateAction<IPrice | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialPricesListView: PricesListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type CategoryListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Category
  setItemForUpdate: Dispatch<SetStateAction<Category | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialCategoryListView: CategoryListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type BrandListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Brand
  setItemForUpdate: Dispatch<SetStateAction<Brand | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialBrandListView: BrandListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type UnitListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Unit
  setItemForUpdate: Dispatch<SetStateAction<Unit | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialUnitListView: UnitListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type TypeListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Type
  setItemForUpdate: Dispatch<SetStateAction<Type | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type OrderListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Order
  setItemForUpdate: Dispatch<SetStateAction<Order | undefined>>
  userID?: string | number
  setUserID: Dispatch<string | number>
  isAllSelected: boolean
  disabled: boolean
}
export const initialOrderListView: OrderListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  setUserID: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}


export const initialTypeListView: TypeListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export type UserSettingListViewContextProps = {
  itemForUpdate?: ID
  setItemForUpdate: Dispatch<SetStateAction<ID | undefined>>
}

export const initialUserSettingListView: UserSettingListViewContextProps = {
  setItemForUpdate: () => { },
}



export interface Option {
  value:number,
  label: string
}

export interface IAntdImage{
  uid: string; name: string; url: string;
}


export interface IFileDetails {
  decodedFile?: File
  length?: number
  id: ID
  product_id?: ID
  path: string
  fileB64: string
  name: string
  originalName: string
  updated_at: string
}

