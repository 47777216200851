import {ID, Response} from '../../../../_adminPage/helpers'
export type File = {
  name?: string
  path?: string
  fileB64?:string
}

export type FilesQueryResponse = Response<Array<File>>

export const initialUser: File = {
  name: 'Avatar.jpg',
  path: 'asdsad.jpg'
}
