import { FC, useState, createContext, useContext} from 'react'
import { ID, WithChildren } from '../../../../helpers'
import { initialUserSettingListView, UserSettingListViewContextProps } from '../../../../helpers_custom'


const ListViewContext = createContext<UserSettingListViewContextProps>(initialUserSettingListView)
const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [itemForUpdate, setItemForUpdate] = useState<ID>()

  return (
    <ListViewContext.Provider
      value={{
        itemForUpdate,
        setItemForUpdate,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProvider, useListView }
