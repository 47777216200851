import { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ID } from '../../../../_adminPage/helpers'
import { initialUser, File } from '../core/_models'
import { useFileModalView } from '../core/FileModalProvider'
import { FileListLoading } from '../components/loading/FileListLoading'
import { documentConnectToProduct, documentRegister, fileRegister, updateUser } from '../core/_requests'
import { useQueryResponseLanguageData } from '../../Language/core/QueryResponseProviderLang'
import { useQueryClient } from 'react-query'

type Props = {
  isUserLoading: boolean
  file: File
}

const newFileSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const FileNewModalForm: FC<Props> = ({ file, isUserLoading }) => {
  const { setFileNameForAdd, isImage, fileNameForAdd } = useFileModalView()

  const fileInputRef = useRef<any>(null);

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [validFiles, setValidFiles] = useState<any[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const progressRefFiles = useRef<HTMLDivElement>(null);
  const uploadRefFiles = useRef<HTMLSpanElement>(null);;
  const progressRefFilesCounter = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const [userForEdit] = useState<File>({
    ...file,
    name: file.name || initialUser.name,
  })


  useEffect(() => {
    setLoading(true)
    let filteredArr = selectedFiles.reduce((acc: any, current: File) => {
      const x = acc.find((item: File) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
    setLoading(false)
    //  setImages([...filteredArr]);

  }, [selectedFiles]);

  const cancel = () => {
    setFileNameForAdd(undefined)
  }


  const preventDefault = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    // e.stopPropagation();
  }

  const dragOver = (e: React.DragEvent<HTMLInputElement>) => {
    console.log('drag over: ', e)
    preventDefault(e);
  }

  const dragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    console.log('drag enter: ', e)
    preventDefault(e);
  }

  const dragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    console.log('drag leave: ', e)
    preventDefault(e);
  }

  const fileInputClicked = () => {
    fileInputRef.current.click();
  }

  const handleFiles = (files: any[]) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles(prevArray => [...prevArray, files[i]] as any);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles(prevArray => [...prevArray, files[i]] as any);
        setErrorMessage('File type not permitted');
        setUnsupportedFiles(prevArray => [...prevArray, files[i]] as any);
      }
    }
  }
  const fileDrop = (e: any) => {
    preventDefault(e);


    const files = e.dataTransfer.files;
    console.log("file drop: ", files)
    if (files.length) {
      handleFiles(files);
    }
  }

  const filesSelected = () => {
    console.log("file input ref: ", fileInputRef)
    if (fileInputRef?.current?.files?.length) {
      handleFiles(fileInputRef?.current?.files);
    }
  }
  const validateFile = (file: any) => {
    console.log("file: ", file)
    const validTypes = ['application/pdf', 'image/jpg', 'image/png', 'image/gif', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/webp', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint', 'application/msword'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  }


  const setFilesPath = async (fieldsToUpdate: any[]) => {
    const newFiles = [];

    const options = {
      onUploadProgress: (progressEvent: any) => {
        // const uploadPercentage = (selectedFiles.length - newFiles.length) > 0 ? Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / (selectedFiles.length - newFiles.length)) : Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        const uploadPercentage = 100;

        if (progressRefFiles.current !== null) {
          progressRefFiles.current.innerHTML = `${uploadPercentage}%`;
          progressRefFiles.current.style.width = `${uploadPercentage}%`;
        }

        if (uploadPercentage === 100 && uploadRefFiles.current !== null) {
          uploadRefFiles.current.innerHTML = 'File(s) Uploaded';

        }
      }
    };
    // uploadRefImgs?.current?.innerHTML = 'File(s) Uploading...';
    for (let i = 0; i < selectedFiles.length; i++) {
      const formData = new FormData();

      try {
        formData.append('file', selectedFiles[i] as any);

        const { data: resp } = await fileRegister(formData, options);
        var FilesData: any = Object.values(resp)[1] as any;
        //response.data.img attr
        var FilesDataArray: File = Object.values(FilesData)[0] as File;
        newFiles.push(FilesDataArray)

        // if (progressRefFilesCounter.current !== null) {
        //   progressRefFilesCounter.current.innerHTML = ` ${newFiles.length}/${selectedFiles.length}`
        // }

      } catch (error) {

        if (progressRefFiles.current !== null && uploadRefFiles.current !== null) {
          uploadRefFiles.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
          progressRefFiles.current.style.backgroundColor = 'red';
        }
      }
    }

    return newFiles;
  }



  const setDocumentsPath = async (filesPath: any[]) => {
    const newFiles = [];
    for (let i = 0; i < filesPath.length; i++) {
      try {
        const { data: resp } = await documentRegister(filesPath[i].originalName, filesPath[i].path, filesPath[i].originalName);
        var FilesData: any = Object.values(resp)[1] as any;
        var FilesDataArray: File = Object.values(FilesData)[0] as File;
        newFiles.push(FilesDataArray)

      } catch (error) {

      }
    }

    return newFiles;
  }


  const setDocumentsToProducts = async (documentPath: any[], newProduct: ID) => {

    for (let i = 0; i < documentPath.length; i++) {
      try {
        const { data: resp } = await documentConnectToProduct(newProduct as ID, documentPath[i].id);
        console.log("data: ", resp)
      } catch (error) {

      }
    }

  }


  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: newFileSchema,
    onSubmit: async (values, { setSubmitting }) => {

      setSubmitting(true)
      try {
        const filesPath = await setFilesPath(validFiles as any)
          const documentsPath = await setDocumentsPath(filesPath)
          const result = await setDocumentsToProducts(documentsPath, fileNameForAdd as ID)
          queryClient.invalidateQueries([`/product/${fileNameForAdd}`])

      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })

  const languageList = useQueryResponseLanguageData()
console.log("lang list: ",languageList)
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}

            {/* end::Label */}

            {/* begin::Image input */}
            <section className="container">
              <div className="drop-container"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              // onClick={fileInputClicked}
              >
                <div className="drop-message">
                  <div className="upload-icon"></div>
                  Drag & Drop images here or click to select file(s)
                </div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple
                  onChange={filesSelected}
                />
              </div>
              <aside>
                {
                  validFiles.length > 0 &&
                  <>
                    <h4>Files</h4>
                    <ul className={"file-manage-item"}>
                      {validFiles.map((file: any) => (
                        <li key={file.name}>
                          <input style={{ marginBottom: '10px' }} value={file.name}></input>  - {file.size} bytes

                         <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Language</label>
                            <div className='col-lg-8 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg'
                              >
                                <option value=''>Select a Language...</option>
                                {languageList.map(language => {
                                  return (<option value={language.id}>{language.name}</option>)
                                })}
                              </select>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Documents type</label>
                            <div className='col-lg-8 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg'>
                                <option value=''>Select a Language...</option>
                                <option value='es'>Termék adatlap</option>
                                <option value='de'>Kezelési útmutató</option>
                                <option value='hu'>Felhasználói kézikönyv</option>
                              </select>
                            </div>
                            <div className="progress-container">
                              <span ref={uploadRefFiles}></span><span ref={progressRefFilesCounter}></span>
                              <div className="progress">
                                <div className="progress-bar" ref={progressRefFiles}></div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                      }</ul>
                  </>
                }
              </aside>
            </section>
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>


        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Add</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <FileListLoading />}
    </>
  )
}

export { FileNewModalForm }
