import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_adminPage/helpers'
import {File} from './_models'


const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
const DOCUMENT_URL = `${APPZ4_BACKEND_URL}/document`
const FILE_URL = `${APPZ4_BACKEND_URL}/file`
const DOCUMENT_CONNECT_TO_PRODUCT = `${APPZ4_BACKEND_URL}/product/`

const createUser = (file: File): Promise<File | undefined> => {
  return axios
    .put(FILE_URL, file)
    .then((response: AxiosResponse<Response<File>>) => response.data)
    .then((response: Response<File>) => response.data)
}

const updateUser = (file: File): Promise<File | undefined> => {
  return axios
    .post(`${FILE_URL}/${file.name}`, file)
    .then((response: AxiosResponse<Response<File>>) => response.data)
    .then((response: Response<File>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${FILE_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${FILE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export function fileRegister(
  formData: FormData,
  options: any
) {
  return axios.post(FILE_URL,
    formData, options,)
}

export function documentRegister(
  name: string,
  path: string,
  originalName: string
) {
  return axios.post(DOCUMENT_URL, {
    name: name,
    path: path,
    originalName: originalName,
    language_id: 1,
    document_type_id: 2
  })
}

export function documentConnectToProduct(
  id:ID,
  documentId: ID,
) {
  return axios.post(DOCUMENT_CONNECT_TO_PRODUCT+id+"/document", {documentId: documentId})
}





export {deleteUser, deleteSelectedUsers,createUser, updateUser}
