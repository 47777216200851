/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import {
  createObjectResponseContext,
  initialQueryResponse,
  isNotEmpty,
  QUERIES,
  WithChildren,
} from '../../../../../_adminPage/helpers'
import { getUserById } from '../../../../partials/layout/header-menus/user-modal/core/_requests'
import { User } from '../../../../partials/layout/header-menus/user-modal/core/_models'
import { useAuth } from '../../../../../app/modules/auth'
import { useListView } from './ListViewProvider'


const QueryResponseContextUser = createObjectResponseContext<User>(initialQueryResponse)
const QueryResponseProviderUser: FC<WithChildren> = ({ children }) => {

  const [query, setQuery] = useState<string>('/userById')
  const { currentUser } = useAuth();

  const { itemForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemForUpdate)

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CURRENT_USER}-${query}`,
    () => {
      return getUserById(currentUser?.data.user?.id + "")
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,enabled:enabledQuery }
  )

  return (
    <QueryResponseContextUser.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContextUser.Provider>
  )
}

const useQueryResponseUser = () => useContext(QueryResponseContextUser)

const useQueryResponseUserData = () => {
  const { response } = useQueryResponseUser()
  if (!response) {
    return []
  }

  return response || []
}


const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponseUser()
  return isLoading
}

export {
  QueryResponseProviderUser,
  useQueryResponseUser,
  useQueryResponseUserData,
  useQueryResponseLoading,
}
