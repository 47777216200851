import axios, {AxiosResponse} from 'axios'
import { LanguageQueryResponse } from './model/_model'



const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL_CLIENT

export const REGISTER_URL = `${APPZ4_BACKEND_URL}/user`


export function getLanguages (): Promise<any> {
  return axios
    .get(`${APPZ4_BACKEND_URL}/language`)
    .then((d: AxiosResponse<LanguageQueryResponse>) => d.data)
}
