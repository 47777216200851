export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string,search?:string,currentSearch?:string) {
  const current = getCurrentUrl(pathname)

  if (!current || !url) {
    return false
  }
  if(search && currentSearch){
    if(currentSearch.indexOf(search) > -1){
      return true
    }
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}
