/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createCustomResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from '../../../../../_adminPage/helpers'
import {getCategories} from './_requests'
import {Category} from './_models'

const QueryResponseContext =createCustomResponseContext<Category>(initialQueryResponse)
const QueryCategoriesResponseProvider: FC<WithChildren> = ({children}) => {
  const [query, setQuery] = useState<string>("/category")
  
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getCategories(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryCategoriesResponse = () => useContext(QueryResponseContext)

const useQueryCategoriesResponseData = () => {
  const {response} = useQueryCategoriesResponse()
  if (!response) {
    return []
  }

  return response || []
}


const useQueryCategoriesResponseLoading = (): boolean => {
  const {isLoading} = useQueryCategoriesResponse()
  return isLoading
}

export {
  QueryCategoriesResponseProvider,
  useQueryCategoriesResponse,
  useQueryCategoriesResponseData,
  useQueryCategoriesResponseLoading,
}
