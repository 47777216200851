import { User } from '../core/_models'
import { UserEditModalForm } from './content/UserEditModalForm'
import { FC } from 'react'

type Props = {
  isLoading: boolean
  user: User
}

const UserEditModalFormWrapper: FC<Props> = ({ user, isLoading }) => {


  const selectType = (user: User, isUserLoading: boolean) => {
      return <UserEditModalForm isUserLoading={isUserLoading} User={user} />
  }



  return (
    <>
      {!user && <div>Loading..</div>}
      {!isLoading && user && selectType(user, isLoading)}

    </>

  )
  // if (!itemForUpdate) {
  //   return <div>Loading...</div>
  // }

  // if (!isLoading && !error && user) {
  // return selectType(user,isLoading)
  // }


}

export { UserEditModalFormWrapper }
