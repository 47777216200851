import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../_adminPage/helpers'

import {ISupplier, SuppliersQueryResponse} from './_models'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL

const SUPPLIERS_URL = `${APPZ4_BACKEND_URL}/supplier`

const getSuppliers = (): Promise<any> => {
  return axios
    .get(`${SUPPLIERS_URL}`)
    .then((d: AxiosResponse<SuppliersQueryResponse>) => d.data.data?.suppliers as ISupplier[])
}


// const updateUser = (admin: Admin): Promise<Admin | undefined> => {
//   return axios
//     .post(`${USER_URL}/${admin.id}`, admin)
//     .then((response: AxiosResponse<Response<Admin>>) => response.data)
//     .then((response: Response<Admin>) => response.data)
// }

const deleteSupplier = (supplierId: ID): Promise<void> => {
  return axios.delete(`${SUPPLIERS_URL}/${supplierId}`).then(() => {})
}



export {deleteSupplier,getSuppliers}
