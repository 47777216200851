import { AxiosError } from "axios";

function isDatabaseError(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

function errorHandle(obj: unknown) {

  const err = obj as AxiosError

  if (err.response?.status == 500) {
    return <div>Server error!</div>
  }

  const errorsList: unknown = Object.values(err.response?.data)[2];
  const isErrorMessage = isDatabaseError(errorsList)


  if (isErrorMessage) {
    const errors = Object.values((errorsList as string))

    return <ul>
      {errors.map((err: any) => {
        return <li>{err}</li>
      })}
    </ul>
  }else{
    return <div>Unknown error!</div>

  }

}

export {
  errorHandle,
}
