/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useQueryCategoriesResponseData } from '../../../../../app/modules/product-categories/components/core/QueryCategoriesResponseProvider'
import { removeSpace } from '../../../../helpers_custom/crud-helper/helpers'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const categoriesData = useQueryCategoriesResponseData()

  return (
    <>
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Key elements</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/key-elements'
        title='Key elements'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen002.svg'
      >
        <SidebarMenuItemWithSub
          to='/supplierss'
          title='Suppliers'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen010.svg'
        >
          <SidebarMenuItem to='/suppliers' title='Suppliers' hasBullet={true} />

        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to='/products'
          title='Products'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm005.svg'
        >
          <>
            {(categoriesData && categoriesData.length > 0) && categoriesData.map((item) => {
              return <SidebarMenuItem to={`/products/${removeSpace(item?.name as string)}`} title={`${item?.name}`} hasBullet={true} key={`${item.name}-${item.id}`} />
            })
            }
            <SidebarMenuItem to={`/products/all-products`} title={`All products`} hasBullet={true} />
          </>
          {/* <SidebarMenuItem to='/products/category1' title='Category1' hasBullet={true} />
          <SidebarMenuItem to='/products/category2' title='Category2' hasBullet={true} />
          <SidebarMenuItem to='/products/category3' title='Category3' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>


        {/* <SidebarMenuItemWithSub
          to='/orders'
          title='Orders'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm009.svg'
        >
          <SidebarMenuItem to='/orders/pending-orders' title='Pending orders' hasBullet={true} />
          <SidebarMenuItem to='/orders/previous-orders' title='Previous orders' hasBullet={true} />
          <SidebarMenuItem to='/orders/completed-orders' title='Completed orders' hasBullet={true} />
          <SidebarMenuItem to='/orders/attention-required' title='Attention required' hasBullet={true} />

        </SidebarMenuItemWithSub> */}

        <SidebarMenuItemWithSub
          to='/users'
          title='Customers'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/communication/com006.svg'
        >
          <SidebarMenuItem to='/users/passive' title='Passive customers' hasBullet={true} />
          {/* <SidebarMenuItem to='/users/deleted' title='Deleted customers' hasBullet={true} /> */}
          <SidebarMenuItem to='/users/active' title='Active customers' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/admins' title='Admin users' fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen049.svg'>
          {/* <SidebarMenuItem to='/admins/admins-list' title='Admins' hasBullet={true} /> */}
          <SidebarMenuItem to='/admins/admins-list/active' title='Active Admins' hasBullet={true} />
          <SidebarMenuItem to='/admins/admins-list/passive' title='Passive Admins' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/orders' title='Orders' icon='/media/icons/duotune/ecommerce/ecm009.svg'>


          <SidebarMenuItem
            to='/orders/orders-list?order_status=1'
            title='New Order'
            hasBullet={true}
            searchStatic="?order_status=1"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=2'
            title='Pre payment'
            hasBullet={true}
            searchStatic="?order_status=2"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=3'
            title='Collecting'
            hasBullet={true}
            searchStatic="?order_status=3"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=4'
            title='Payment pending'
            hasBullet={true}
            searchStatic="?order_status=4"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=5'
            title='Paid'
            hasBullet={true}
            searchStatic="?order_status=5"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=6'
            title='Under delivery'
            hasBullet={true}
            searchStatic="?order_status=6"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=7'
            title='Delivered'
            hasBullet={true}
            searchStatic="?order_status=7"
          />
          <SidebarMenuItem
            to='/orders/orders-list?order_status=99'
            title='Canceled'
            hasBullet={true}
            searchStatic="?order_status=99"
          />
          <SidebarMenuItem
            to='/orders/orders-list'
            title={'All Orders'}
            fontIcon='bi-archive'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>


      </SidebarMenuItemWithSub >




      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Features</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/features'
        title='Features'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen008.svg'
      >
        <SidebarMenuItem to='/prices/price-category' title='Price Margin Categories' hasBullet={true} />
        {/* <SidebarMenuItem to='/documents/document-list' title='Documents' hasBullet={true} /> */}
        <SidebarMenuItem to='/categories/categories-list' title='Product Categories' hasBullet={true} />
        <SidebarMenuItem to='/brands/brand-list' title='Product Brands' hasBullet={true} />
        <SidebarMenuItem to='/types/types-list' title='Product Types' hasBullet={true} />
        <SidebarMenuItem to='/units/units-list' title='Unit Types' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/*S <idebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
    S  </idebarMenuItemWithSub> */}
      {/*S <idebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
    S  </idebarMenuItemWithSub> */}
      {/*S <idebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
          <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
    S  </idebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/*S <idebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
    S  </idebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={'#'}
          id='kt_activities_toggle'
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Activity log placeholder</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
