export const tradeData = [
  { id: "0", name: "Wholesale" },
  { id: "1", name: "Retail" },
  { id: "2", name: "Distributor" }
];


export const permissionLabels = [
  { id: "0", name: "Admin" },
  { id: "1", name: "Staff" },
  { id: "2", name: "Local Amin" },
  { id: "3", name: "Supplier" },
  { id: "4", name: "Customer" },

];

export const orderStatus = [
  { id: "1", name: "New order" },
  { id: "2", name: "Pre payment" },
  { id: "3", name: "Collecting" },
  { id: "4", name: "Payment pending" },
  { id: "5", name: "Paid" },
  { id: "6", name: "Under delivery" },
  { id: "7", name: "Delivered" },
  { id: "8", name: "Attention required" },
  { id: "99", name: "Canceled" },
];

export const invoiceStatus = [
  { id: "1", name: "Issued" },
  { id: "2", name: "Payment pending" },
  { id: "3", name: "Paid" },
  { id: "99", name: "Canceled" },
];
