import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_adminPage/helpers'
import { IFileDetails } from '../../../../../_adminPage/helpers_custom'
import { BrandsQueryResponse, CategoriesQueryResponse, IProductDetails, ProductIdQueryResponse, ProductsQueryResponse, TypesQueryResponse, UnitsQueryResponse } from './_models'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const GET_PRODUCTS_URL = `${APPZ4_BACKEND_URL}/product`
export const DELETE_IMAGE_URL = `${APPZ4_BACKEND_URL}/productImage`



const GET_CATEGORIES_URL = `${APPZ4_BACKEND_URL}/category`
const GET_BRANDS_URL = `${APPZ4_BACKEND_URL}/manufacturer`
const GET_UNITS_URL = `${APPZ4_BACKEND_URL}/unit`
const GET_TYPES_URL = `${APPZ4_BACKEND_URL}/productType`
const FILE_URL = `${APPZ4_BACKEND_URL}/file`
const DOCUMENT_URL = `${APPZ4_BACKEND_URL}/document`


//Only Product Table Page APIS START
const getProducts = (query: string): Promise<any> => {
  return axios
    .get(`${GET_PRODUCTS_URL}`)
    .then((d: AxiosResponse<ProductsQueryResponse>) => d?.data?.data?.products)
}

const deleteProduct = (userId: ID): Promise<void> => {
  return axios.delete(`${GET_PRODUCTS_URL}/${userId}`).then(() => { })
}

//End Only Product Table APIS



//EDIT PRODUCT PAGE APIS START
const getCategories = (): Promise<any> => {
  return axios
    .get(`${GET_CATEGORIES_URL}`)
    .then((d: AxiosResponse<CategoriesQueryResponse>) => d.data.data?.categories)
}

const getBrands = (): Promise<any> => {
  return axios
    .get(`${GET_BRANDS_URL}`)
    .then((d: AxiosResponse<BrandsQueryResponse>) => d.data.data?.manufacturers)
}
const getUnits = (): Promise<any> => {
  return axios
    .get(`${GET_UNITS_URL}`)
    .then((d: AxiosResponse<UnitsQueryResponse>) => d.data.data?.units)
}

const getTypes = (): Promise<any> => {
  return axios
    .get(`${GET_TYPES_URL}`)
    .then((d: AxiosResponse<TypesQueryResponse>) => d.data.data?.productTypes)
}

const getImages = (base64: string): Promise<any> => {
  return axios
    .get(`${FILE_URL}/${base64}`)
    .then((d: AxiosResponse<any>) => d.data)
}
const getProductById = (id: string): Promise<any> => {
  return axios
    .get(`${GET_PRODUCTS_URL}/${id}`)
    .then((response: AxiosResponse<ProductIdQueryResponse>) => response.data.data?.product)
}

const createDocument = (product: IProductDetails): Promise<IProductDetails | undefined> => {
  return axios
    .put(GET_PRODUCTS_URL, product)
    .then((response: AxiosResponse<Response<IProductDetails>>) => response.data)
    .then((response: Response<IProductDetails>) => response.data)
}

export function updateProduct(
  id: string,
  name: string,
  price: number,
  category_id: string,
  product_type_id: string,
  manufacturer_id: string,
  highlight: number,
  unit_id: ID,
  min_units: number,
  images: IFileDetails[],
  documents: IFileDetails[],
  active: number,
  description: string,
  warranty: string
) {
  return axios.patch(GET_PRODUCTS_URL + "/" + id, {
    name,
    price,
    category_id,
    product_type_id,
    manufacturer_id,
    highlight,
    unit_id,
    min_units,
    images,
    documents,
    active,
    description,
    warranty
  })
}

export function deleteDocument(
  id: ID,
  documentId: string,
) {
  const data = { documentId: documentId }
  return axios.delete(GET_PRODUCTS_URL + "/" + id + "/document", {data:data
  })
}
//EDIT PRODUCT PAGE APIS END



export function deleteImage(
  id: ID
) {
  return axios.delete(DELETE_IMAGE_URL + "/" + id)
}



//NEW PRODUCT PAGE APIS START
export function fileRegister(
  formData: FormData,
  options?: any
) {
  return axios.post(FILE_URL,
    formData, options,)
}

export function documentRegister(
  name: string,
  path: string,
  originalName: string
) {
  return axios.post(DOCUMENT_URL, {
    name: originalName,
    path: path,
    originalName: originalName,
    language_id: 1,
    document_type_id: 2
  })
}

export function documentConnectToProduct(
  id: ID,
  documentId: ID,
) {
  return axios.post(GET_PRODUCTS_URL + "/" + id + "/document", { documentId: documentId })
}

export const REGISTER_URL = `${APPZ4_BACKEND_URL}/product`

export function addProduct(
  data: IProductDetails
) {
  return axios.post(REGISTER_URL, {
    name: data.name,
    price: Number(data.price),
    category_id: data.productCategory,
    highlight: data.isBestSelling ? '1' : '0',
    active: '1',
    product_type_id: data.typeCategory,
    manufacturer_id: data.brandCategory,
    unit_id: data.unitType,
    images: data.images,
    min_units: data.minOrderQuantity,
    description: data.description,
    warranty: data.warranty
  })
}
//NEW PRODUCT PAGE APIS END


export { getProducts, deleteProduct, getCategories, createDocument, getProductById, getImages, getTypes, getUnits, getBrands }
