import {Dispatch, SetStateAction} from 'react'
import {  IProductDetails } from '../../../app/modules/products/components/core/_models'
import { IFileDetails, StatusState } from '../../helpers_custom'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  items_per_page: 10 | 30 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & StatusState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  itemIdForAdd?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  setItemIdForAdd: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIdForAdd: () => {},
  isAllSelected: false,
  disabled: false,
}

export type FileModalContextProps = {
  fileNameForUpdate?: IFileDetails | undefined
   fileNameForAdd?: string
   isImage?: boolean
   setFileNameForUpdate: Dispatch<SetStateAction<IFileDetails | undefined>>
   setFileNameForAdd: Dispatch<SetStateAction<string | undefined>>
   setIsImage: Dispatch<SetStateAction<boolean>>
 }
 
 export type ImgModalContextProps = {
  imgNameForUpdate?: IFileDetails | undefined
   selectedProduct?: IProductDetails | undefined
   setImgNameForUpdate: Dispatch<SetStateAction<IFileDetails | undefined>>
    setSelectedProduct: Dispatch<SetStateAction<IProductDetails | undefined>>
 }
 

export const initialFileModalView: FileModalContextProps = {
  setFileNameForUpdate: () => {},
  setFileNameForAdd: () => {},
  setIsImage: () => {}
}

export const initialImgModalView: ImgModalContextProps = {
  setImgNameForUpdate: () => {},
  setSelectedProduct: () => {},
}

export interface PaginationProps {
  pageIndex: number
  pageOptions: number[]
  nextPage: () => void
  previousPage: () => void
  canNextPage:  boolean,
  canPreviousPage:  boolean
  setPageSize: (arg0: number) => void;
  gotoPage: (arg0: number) => void;
}

export type CustomQueryResponseContextProps<T> = {
  response?: Array<T> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type CustomQueryResponseContextProps2<T> = {
  response?: T | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}


export const initialQueryRequestTwo: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}