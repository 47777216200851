import {Response} from '../../../../_adminPage/helpers'
import { Img } from './Img';
export type File = {
  [key: string]: any;
  name?: string
  path?: string
  fileB64?:string
  size?:string
}


export class ImgClass implements Img {
  private _name: string | undefined;
  private _path: string | undefined;
  private _fileB64: string | undefined;
  private _size: string | undefined;

  public get Name() {
      return this._name;
  }

  public set Name(value) {
      this._name = value;
  }
}


export type FilesQueryResponse = Response<Array<File>>

export const initialUser: File = {
  name: 'Avatar.jpg',
  path: 'asdsad.jpg'
}
