import { QueryCountryResponseProvider } from './Country/core/QueryResponseProviderCountry';
import { QueryLanguageResponseProvider } from './Language/core/QueryResponseProviderLang';
import { QueryCategoriesResponseProvider } from '../modules/product-categories/components/core/QueryCategoriesResponseProvider';
import { QueryResponseSuppliersProvider } from './Suppliers/QueryResponseProvider';
import { Outlet } from 'react-router-dom'
import { FileModalProvider } from './fileModal/core/FileModalProvider';
import { ImgModalProvider } from './imgModal/core/ImgModalProvider';

const ContextsWrapper = () => {
    return (

        <QueryResponseSuppliersProvider>
            <QueryCategoriesResponseProvider>
                <QueryCountryResponseProvider>
                    <QueryLanguageResponseProvider>
                        <FileModalProvider>
                            <ImgModalProvider>
                                <Outlet />
                            </ImgModalProvider>
                        </FileModalProvider>
                    </QueryLanguageResponseProvider>
                </QueryCountryResponseProvider>
            </QueryCategoriesResponseProvider>
        </QueryResponseSuppliersProvider>
    )



}

export { ContextsWrapper }