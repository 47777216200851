/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { requestPassword } from '../core/_requests'
import { useParams, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_adminPage/assets/ts/components'
import EyeButton from '../../../../_adminPage/layout/components/eye-button/EyeButton'

const initialValues = {
    password: '',
    changepassword: '',
    token: ''
}

const passwordChangeSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        })
})

export function ForgottenPasswordForm() {
    const [loading, setLoading] = useState(false)
    const [isSent, setIsSent] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const { token } = useParams();
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema: passwordChangeSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const { data: resp } = await requestPassword(
                    token ? token : 'null',
                    values.password,
                    values.changepassword
                )
                setLoading(false)
                setIsSent(true)
                setStatus('The password has changed!')
                setTimeout(() => {
                    navigate("/");
                }, 1000)
            } catch (error) {
                console.error(error)
                setStatus('The password details is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
        enableReinitialize: true,

    })


    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='mb-10 text-center'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>New Password</h1>
                {/* end::Title */}
            </div>
            {/* end::Heading */}


            {(formik.status && !isSent) && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            {(formik.status && isSent) && (
                <div className='mb-lg-15 success alert-success'>
                    <div className='success-text font-weight-bold'>{formik.status}</div>
                </div>
            )}
            {/* begin::Form group Password */}
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
                <div className='mb-1 position-relative'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <div className='mb-3'>
                        <input
                            type={passwordType}
                            placeholder='Password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>

                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* begin::Meter */}
                    <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                        },
                        {
                            'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                        }
                    )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* begin::Form group */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>

            </div>
            {/* end::Form group */}
        </form>
    )
}
