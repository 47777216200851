import axios, {AxiosResponse} from 'axios'
import { createCountryOptions } from '../../../../_adminPage/core/functions/helper'
import { Country, CountryQueryResponse } from './model/_model'



const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL_CLIENT
export const REGISTER_URL = `${APPZ4_BACKEND_URL}/user`



export async function getCountries () {

  return axios
    .get(`${APPZ4_BACKEND_URL}/country`)
    .then((d: AxiosResponse<CountryQueryResponse>) => {   
return d.data
})
}
